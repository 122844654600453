import provider from "./config";
import axios from "axios";

export async function signIn(payload) {
  try {
    const response = await provider.post("/authentication/", payload);

    const data = response.data;
    const last_name = data.entidade.nome.split(' ');
    const first_name = last_name.shift()

    const user = {
      "id": 1,
      "token": data.token,
      "is_superuser": true,
      "is_staff": true,
      "is_active": true,
      "username": data.entidade.email,
      "first_name": first_name,
      "last_name": last_name,
      "email": data.entidade.email,
      "date_joined": data.entidade.criadoEm,
      "groups": [],
      "user_permissions": []
    };
    return user;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function updateManager(id, payload, token) {
  try {
    const response = await axios.patch(
      `https://shop-manager.devari.com.br/api/v1/admin/user/${id}/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function updateProfile(id, payload, token) {
  try {
    const response = await provider.patch(`/admin/user/${id}/`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    if (Array.isArray(err.response.data) && err.response.data.length > 0) {
      return { error: true, errorMessage: err.response.data[0] };
    }
    return { error: true, message: err.message };
  }
}

export async function getStore() {
  try {
    const response = await provider.get("/entidades", {
      params: {
        classe: 'Estabelecimentos',
      }
    });

    const baseStore = response.data[0];

    const store = {
      "id": baseStore.id,
      "name": baseStore.nome,
      "logo": baseStore.foto,
      "pickup": false,
      "credit_pay": true,
      "brazilian_boleto_pay": true,
      "pix_pay": false,
      "favicon": baseStore.foto,
      "phone": baseStore.telefone,
      "email": baseStore.email,
      "whatsapp_number": baseStore.telefone,
      "facebook": baseStore.facebook || null,
      "instagram": baseStore.instagram || null,
      "twitter": baseStore.twitter || null,
      "description": baseStore.observacao,
      "address_street": baseStore.cep === '00000-000' ? 'On-line' : baseStore.endereco,
      "address_state": baseStore.cep === '00000-000' ? '' : baseStore.UF.nome,
      "address_postcode": baseStore.cep,
      "address_city": baseStore.cep === '00000-000' ? '' : baseStore.Cidade.nome,
      "address_district": baseStore.cep === '00000-000' ? '' : baseStore.bairro,
      "address_number": baseStore.cep === '00000-000' ? '' : baseStore.numeroEndereco,
      "address_complement": baseStore.cep === '00000-000' ? '' : baseStore.complemento,
      "events": [],
    }
    return store;
  } catch (err) {
    return { error: true, message: err.message };
  }
}

export async function updateStore(token, payload) {
  try {
    const response = await provider.patch("/store/1/", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    return { error: true, message: err.message };
  }
}
